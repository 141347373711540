<template>
  <v-btn
    class="global-btn global-pbtn"
    depressed
    style="border-radius: 5px"
    large
    :width="width"
    :to="to"
    :loading="loading"
    :height="height"
    :color="btnColor"
    :disabled="disabled"
    :block="block"
    >{{ label }}</v-btn
  >
</template>

<script>
import color from "@/config";

export default {
  name: "v-btn-principal",
  props: [
    "label",
    "color",
    "height",
    "width",
    "to",
    "disabled",
    "loading",
    "block",
  ],
  computed: {
    btnColor() {
      return color.colorBotonPrincipal;
    },
  },
};
</script>
<style scoped src="../../assets/CSS/ServiceStyle.css"></style>
